import { BrowserRouter, Routes, Route } from 'react-router-dom';
import List from './pages/list/List';
import Dua from './pages/dua/Dua';



const App = () => {

  return (
    <div>
      <BrowserRouter>
        <Routes>
            <Route path={`/`} element={<List />} />
            <Route path={`/dua/:id?`} element={<Dua/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};


export default App;
