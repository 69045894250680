import React, {useState, useHistory} from 'react'
import { useNavigate } from "react-router-dom";
import { TOPICS } from 'commons/data/topics';
import styled from '@emotion/styled'
import ListItem from './components/listItem/ListItem'
import HeaderComponent from 'commons/components/header/Header';

const Container = styled.div`
  max-width: 1180px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`
const Header = styled.div`
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h2`
  font-size: 24px;
  text-align: center;
  @media (max-width: 1180px) {
    font-size: 16px;
  }
`
const Input = styled.input`
  border: 1px solid #ccc;
  padding: 15px 20px;
  border-radius: 8px;
  color: #000;
  box-shadow: 0px 0px 9pt 2pt #ccc;
  width: 300px;
  &:focus {
    outline: 1px solid #AA6F3D;
    border-color: #AA6F3D;
  }
`;
const ListContainer = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

function List() {
  const navigate = useNavigate();
  const [topics, setTopics] = useState(TOPICS);
  const [searchText, setSearchText] = useState('');

    const navigateToDuaPage = (id) => {
      navigate(`/dua/${id}`);
    };

  const handleChange = (e) => {
    const searchText = e.target.value;
    const topics = TOPICS.filter((topic) =>
      topic.name.toLowerCase().includes(searchText.toLowerCase()),
    );
    setSearchText(searchText);
    setTopics(topics);
  }

  return (
    <>
      <HeaderComponent/>
      <Container>
        <Header>
          <Title>List of authentic Dua from Quran and Hadith, used in daily life for various purposes</Title>
          <Input
            onChange={handleChange}
            value={searchText}
            placeholder="Search..."
          />
        </Header>
        <ListContainer>
          {topics.map((item) => (
            <ListItem item={item} key={item.id} navigate={() => navigateToDuaPage(item.id)} />
          ))}
        </ListContainer>
      </Container>
    </>
  );
}

export default List;
