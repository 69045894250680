export const TOPICS = [
  {
    name: 'Magfirat aur Rahmat talab karne ki duayein',
    pageNo: 13,
    id: 1,
  },
  {
    name: 'Gunahon ki Bakhshish talab karne ki duayein',
    pageNo: 15,
    id: 2,
  },
  {
    name: 'Talab e Rizq aur Rizq me Barkat ki Dua',
    pageNo: 21,
    id: 3,
  },
  {
    name: 'Fitno se bachao ke liye Dua',
    pageNo: 22,
    id: 4,
  },
  {
    name: 'Talab e Ilm ki Dua',
    pageNo: 24,
    id: 5,
  },
  {
    name: 'Aulaad talab karne ki Duayein',
    pageNo: 25,
    id: 6,
  },
  {
    name: 'Aulaad ki Islaah ki Duayein',
    pageNo: 26,
    id: 7,
  },
  {
    name: 'Madad talab karne ki Dua',
    pageNo: 28,
    id: 8,
  },
  {
    name: 'Walidayn ki Magfirat ke liye Duayein',
    pageNo: 29,
    id: 9,
  },
  {
    name: 'Sabr aur Sabitqadmi ki Duayein',
    pageNo: 30,
    id: 10,
  },
  {
    name: 'Amaale Saaliha aur Nematon par shukr ki tawfeeq mangne ki dua',
    pageNo: 31,
    id: 11,
  },
  {
    name: 'Qayamat ke din kaamil noor talab karne ki Dua',
    pageNo: 32,
    id: 12,
  },
  {
    name: 'Dua ki qubooliyat ke liye Duayein',
    pageNo: 33,
    id: 13,
  },
  {
    name: 'Hikmat aur Jannat Nayeem talab karne ki Dua',
    pageNo: 34,
    id: 14,
  },
  {
    name: 'Deen par Saabitqadmi ki Dua',
    pageNo: 35,
    id: 15,
  },
  {
    name: 'Allah ki Rahmat, Raza aur Jannat talab karne ki Dua',
    pageNo: 37,
    id: 16,
  },
  {
    name: 'Rahmat aur Saabitqadmi ki Duayein',
    pageNo: 38,
    id: 17,
  },
  {
    name: 'Husn e Khaatma ki Duayein',
    pageNo: 40,
    id: 18,
  },
  {
    name: 'Qayamat ke din aasaan Hisaab ki Dua',
    pageNo: 41,
    id: 19,
  },
  {
    name: 'Hidayat aur Taqwa haasil karne ki Dua',
    pageNo: 41,
    id: 20,
  },
  {
    name: 'Ilm Nafi" ki Dua',
    pageNo: 43,
    id: 21,
  },
  {
    name: 'Qarz ki Duayein',
    pageNo: 43,
    id: 22,
  },
  {
    name: 'Sakht Aazmaish aur Taqdeer ki Burayi se Panah ki Dua',
    pageNo: 46,
    id: 23,
  },
  {
    name: 'Bure Akhlaaq se Panah ki Duayein',
    pageNo: 47,
    id: 24,
  },
  {
    name: 'Nemat ki chhin jaane aur Allah ke azaab se Panah ki Duayein',
    pageNo: 48,
    id: 25,
  },
  {
    name: 'Khauf e Ilahi se khaali dilke liye panah ki Duayein',
    pageNo: 49,
    id: 26,
  },
  {
    name: 'Har Khair ka Sawaal aur har Shar se Panah ki Dua',
    pageNo: 51,
    id: 27,
  },
  {
    name: 'Allah ki Mohabbat haasil karne ki Dua',
    pageNo: 56,
    id: 28,
  },
  {
    name: 'Qabr ke Azaab aur Zindagi aur Maut ke Fitne se Panah ki Dua',
    pageNo: 58,
    id: 29,
  },
  {
    name:
      'Kaan, Aankh, Zabaan, Dil aur Sharmgaah ke Shar se Panah mangne ki Dua',
    pageNo: 61,
    id: 30,
  },
  {
    name:
      'Apne kiye hue aur na kiye hue A"maal ke Shar se Panah maangne ki Dua',
    pageNo: 62,
    id: 31,
  },
  {
    name: 'Bure saathi aur Bure Hamsafar se panah mangne ki Dua',
    pageNo: 63,
    id: 32,
  },
  {
    name: 'Tamam kaamon me behtarin anjaaam ki Dua',
    pageNo: 64,
    id: 33,
  },
  {
    name: 'Azaab e Jahannam se panah talab karne ki Dua',
    pageNo: 65,
    id: 34,
  },
  {
    name: 'Dunya o Aakhirat ke khair talab karne ki Duayein',
    pageNo: 66,
    id: 35,
  },
  {
    name: 'Sawari se utarne ki Dua',
    pageNo: 71,
    id: 36,
  },
  {
    name: 'Shaitani waswase se bachao ki Duayein',
    pageNo: 72,
    id: 37,
  },
  {
    name: 'Gair nafa bakhsh namaz se panah ki Dua',
    pageNo: 73,
    id: 38,
  },
  {
    name: 'Muhtaaji maal ki kami, zillat aur Zulm se panah mangne ki Dua',
    pageNo: 74,
    id: 39,
  },
  {
    name: 'Haasidon ke Shar se bachao ki Dua',
    pageNo: 75,
    id: 40,
  },
  {
    name: 'Din ki Dawat me Asaani ki Dua',
    pageNo: 76,
    id: 41,
  },
  {
    name: 'Apne bachcho aur Biwi ke Haq me Dua',
    pageNo: 76,
    id: 42,
  },
  {
    name: 'Bhuk aur Khayanat se panah mangne ki Dua',
    pageNo: 77,
    id: 43,
  },
  {
    name: 'Rukhsat karte waqt ki Dua',
    pageNo: 78,
    id: 44,
  },
  {
    name: 'Gumrahi se panah mangne ki Dua',
    pageNo: 78,
    id: 45,
  },
  {
    name: 'Hidayat aur Islaah ki Duayein',
    pageNo: 79,
    id: 46,
  },
  {
    name:
      'Bars, Kodh, Pagalpan aur digar badtareen bimaariyon se panah mangne ki Dua',
    pageNo: 80,
    id: 47,
  },
  {
    name:
      'Girne, Dubne, Jalne, Budhape aur Maut ke Waqt Shaitaab ke Hamle se panah mangne ki Dua',
    pageNo: 81,
    id: 48,
  },
  {
    name: 'Tasbeehaat',
    pageNo: 82,
    id: 49,
  },
  {
    name: 'Mareez ke liye Duayein',
    pageNo: 84,
    id: 50,
  },
  {
    name: 'Nazr e Bad se bachne ki Duayein',
    pageNo: 86,
    id: 51,
  },
];
