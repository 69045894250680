import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useParams, useNavigate } from "react-router-dom";
import { getDua, getTopic } from 'commons/utils/utils';
import HeaderComponent from 'commons/components/header/Header';
import BackIcon from 'commons/icons/BackIcon.svg';

const HideInMobile = styled.div`
  @media (max-width: 1180px) {
    display: none;
  }
`
const TitleWrapper = styled.div`
  display: none;
  background-color: #AA6F3D;
  color: #f9f9f9;
  margin-bottom: 15px;
  // justify-content: space-between;
  align-items: center;
  div{
    width: 60px;
    height: 60px;
    &:focus, &:active{
      background-color: #714928;
    }
  }
  img{
    width: 24px;
    position: relative;
    left: 15px;
    top: 17px;
  }
  h2{
    margin: 0;
    padding: 20px 15px 20px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }
  @media (max-width: 1180px) {
    display: flex;
  }
`
const Container = styled.div`
  max-width: 1180px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
`
const Title = styled.h2`
  font-size: 24px;
  text-align: center;
  @media (max-width: 1180px) {
    font-size: 16px;
  }
`
const Card = styled.div`
  padding: 15px;
  cursor: pointer;
  background-color: #fdfcfa;
  margin-bottom: 15px;
  box-shadow: rgba(184, 193, 205, 0.2) 0px 1px 4px, rgba(235, 239, 248, 0.1) 1px 4px 6px;
  border-radius: 8px;
  &:hover {
    background-color: #eee;
  }
`;
const CardItem = styled.div`
  margin-bottom: 20px;
  text-align: center;
`
const Arabic = styled.div`
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
`

function Dua() {
  const { id } = useParams();
  const [dua, setDua] = useState({});
  const navigate = useNavigate();
  
  useEffect(() => {
    setDua(getDua(id));
  }, [id]);


  return (
    <>
      <HideInMobile>
        <HeaderComponent/>
      </HideInMobile>
      <TitleWrapper>
        <div onClick={() => {navigate(-1)}}><img src={BackIcon} alt="go back" /></div>
        <Title className='roboto-medium'>
          {getTopic(id)}
        </Title>
      </TitleWrapper>
      <Container>
        <HideInMobile>
          <Title className='roboto-medium'>{getTopic(id)}</Title>
        </HideInMobile>
        <div>
          {
          dua.eng && Object.keys(dua.eng).map((key) => (
            <Card key={key}>
              <Arabic className='noto-naskh-arabic'>{dua.arabic[key]}</Arabic>
              <CardItem>{dua.eng[key]}</CardItem>
              <Arabic className='gulzar-regular'>{dua.urdu[key]}</Arabic>
              <CardItem>{dua.roman[key]}</CardItem>
              <CardItem>Reference: [{dua.ref[key]}]</CardItem>
            </Card>
          ))
          }
        </div>
      </Container>
    </>
  )
}

export default Dua