import React from 'react'
import styled from '@emotion/styled'

const Header = styled.div`
  background-color: #AA6F3D;
`;
const Container = styled.div`
  max-width: 1180px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`
const HeaderContent = styled.div`
  padding: 20px;
  display: flex;
  color: #f9f9f9;
  font-size: 36px;
  justify-content: space-between;
  text-transform: uppercase;
  align-items: center;
`;

const HeaderComponent = () => {
  return (
    <Header>
      <Container>
        <HeaderContent>
          <div className='roboto-thin'>Jaame' Dua</div>
          <div style={{textAlign: 'right'}} className='gulzar-regular'>جامع دعاء</div>
        </HeaderContent>
      </Container>
    </Header>
  )
}

export default HeaderComponent