import React from 'react'
import styled from '@emotion/styled'

const Items = styled.div`
  padding: 15px;
  cursor: pointer;
  background-color: #fdfcfa;
  margin-bottom: 5px;
  flex: 1 0 calc(50% - 40px);
  @media (max-width: 1180px) {
    flex: 1 0 calc(100% - 35px);
    &+div{
      margin-left: 0px;
    }
  }
  &+div{
    margin-left: 5px;
  }
  &:hover {
    background-color: #eee;
  }
`;

function ListItem({item, navigate}) {
  return (
    <Items onClick={navigate}>{item.id}. {item.name}</Items>
  )
}

export default ListItem