import ARABIC from 'commons/data/arabic.json';
import ENGLISH from 'commons/data/english.json';
import REF from 'commons/data/reference.json';
import ROMAN from 'commons/data/romanUrdu.json';
import URDU from 'commons/data/urdu.json';
import { TOPICS } from 'commons/data/topics.js';

export const getDua = (id) => {
  return {
    arabic: ARABIC[id],
    eng: ENGLISH[id],
    ref: REF[id],
    roman: ROMAN[id],
    urdu: URDU[id]
  }
}

export const getTopic = (id) => {
  return TOPICS.filter((topic) => topic.id.toString() === id)[0].name;
}